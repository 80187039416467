import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const aboutQuery = graphql`
  query getAboutMd {
    markdownRemark(frontmatter: { path: { eq: "/about" } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

const AboutPage = () => {
  const data = useStaticQuery(aboutQuery)
  const { markdownRemark: { html }} = data
  return (
    <Layout>
      <Seo title="About" />
      <h1>About</h1>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Layout>
  )
}

export default AboutPage
